import { useEffect, useState } from 'react'
import { Container, Nav, Navbar, NavbarToggler, Collapse } from 'reactstrap'
import { Link } from 'react-scroll'

// Static UI imports
import Menu from './Menu'

// Static Assets
import LogoIcon from '../assets/images/logo-icon@2x.png'

interface Props {
  connectWalletHandler: () => void
  walletAddress: string
  showLoader: boolean
  showValidateLoader: boolean
}

function Header({
  connectWalletHandler,
  showLoader,
  walletAddress,
  showValidateLoader,
}: Props) {
  const [collapsed, setCollapsed] = useState<boolean>(false)
  useEffect(() => {
    const header = document.getElementById('appHeader')
    if (header) {
      const sticky = header.offsetTop
      window.addEventListener('scroll', () => {
        if (window.pageYOffset > sticky) {
          header.classList.add('is-sticky')
        } else {
          header.classList.remove('is-sticky')
        }
      })
    }
  })
  return (
    <>
      <header className="header-horizontal" id="appHeader">
        <Container className="">
          <div className="d-flex align-items-center justify-content-between">
            <a href="/" className="cursor-pointer">
              <img src={LogoIcon} className="header-logo" alt="Acrocalypse" />
            </a>
            <Nav className="header-menu header-menu-sm">
              <Menu
                setCollapsed={() => {}}
                collapsed={collapsed}
                className="header-menu-item"
              />
            </Nav>

            <Navbar
              color="faded"
              light
              className={`${collapsed ? `navigation-active` : ``}`}
            >
              <NavbarToggler
                className=""
                onClick={() => setCollapsed(!collapsed)}
              />
              <Collapse className="responsive-navigation" isOpen={collapsed}>
                <div
                  className="close-menu"
                  style={{ display: 'flex' }}
                  data-uw-styling-context="true"
                  onClick={() => setCollapsed(!collapsed)}
                >
                  <span
                    className="icon-close"
                    data-uw-styling-context="true"
                  ></span>
                </div>
                <Menu setCollapsed={setCollapsed} collapsed={collapsed} />
              </Collapse>
              {collapsed && (
                <div
                  className="menu-overlay"
                  onClick={() => setCollapsed(!collapsed)}
                ></div>
              )}
            </Navbar>

            <div className="">
              {!walletAddress && (
                <button
                  type="button"
                  className={`btn-mint-a-croc cursor-pointer ${
                    showLoader ? `is-loading` : ``
                  }`}
                  onClick={connectWalletHandler}
                >
                  CONNECT
                </button>
              )}

              {walletAddress && (
                <Link
                  to="btn-claim"
                  spy={true}
                  smooth={true}
                  offset={-50}
                  duration={500}
                  className={`btn-mint-a-croc cursor-pointer ${
                    showValidateLoader ? `is-loading` : ``
                  }`}
                >
                  MINT
                </Link>
              )}
            </div>
          </div>
        </Container>
      </header>
    </>
  )
}

export default Header
