// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const getCatchError = (error: any) => {
  let returnData = [];
  if (error.response) {
    const response = error.response;
    if ('data' in response && response.data.errors) {
      returnData = extractErrorFromResponseDataObject(response.data.errors);
    } else {
      returnData.push('Server encountered error processing the request');
    }
  } else if (error.request) {
    returnData.push(error.message);
  } else {
    returnData.push(error.message);
  }

  return returnData;
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const errorMessageSeparator = (errObj: any) => {
  return extractErrorFromResponseDataObject(errObj);
};
// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const extractErrorFromResponseDataObject = (errObj: any) => {
  const returnData = [];
  if (errObj !== null && typeof errObj === 'string') {
    returnData.push(errObj);
  } else {
    if ('errorMessage' in errObj) {
      returnData.push(errObj.errorMessage);
    } else {
      const errorArray = errObj;
      for (const errorString in errorArray) {
        if (Array.isArray(errorArray[errorString])) {
          for (let i = 0; i < errorArray[errorString].length; i++) {
            returnData.push(errorArray[errorString][i]);
          }
        } else {
          returnData.push(errorArray[errorString].msg);
        }
      }
    }
  }

  return returnData;
};

export default errorMessageSeparator;
