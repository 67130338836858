import { FunctionComponent } from 'react';
import { Container, Row, Col, Table } from 'reactstrap';
// import { Input } from "reactstrap";
import NumberFormat from 'react-number-format';

import { ClaimBreakdownValues } from '../config/Constant';

// Static Assets
import IconUfo from '../assets/images/icons/ufo.png';
import IconDestroyer from '../assets/images/icons/destroyer.png';
import IconBlastBike from '../assets/images/icons/blastbike.png';
import IconDropship from '../assets/images/icons/dropship.png';

import Destroyer from '../assets/images/destroyer.gif';

interface Props {}

const SectionClaimBreakdown: FunctionComponent<Props> = () => {
  return (
    <>
      <>
        <section className="section-claim-breaddown">
          <div className="mx-auto w-wrapper-lg">
            <div className="mx-auto w-100 mb-100">
              <a
                href="https://opensea.io/collection/acrocspacecraft"
                target="_blank"
                rel="noreferrer"
                className="btn-view-collection"
              >
                VIEW COLLECTION ON OPENSEA
              </a>
            </div>

            <div className="mx-auto w-100 mb-125">
              <Container>
                <Row className="align-items-center">
                  <Col lg={6} md={6}>
                    <div className="w-100 pr-4">
                      <h1 className="title mb-32 title-mobile-center">
                        SOMETHING'S COMING...
                      </h1>
                      <p className="fs-24 text-grey">
                        Here we have 10,420 Acrocalypse Spacecraft itching to explode into
                        orbit with their croc's at the helm. These spacecraft will come in
                        tremendously useful with the upcoming missions that your crocs
                        will be embarking on in the coming months. Whether you are called
                        up for attacking invasions or looking to defend the galaxy,
                        spacecraft will be sure to increase your success.
                      </p>
                    </div>
                  </Col>
                  <Col lg={6} md={6}>
                    <div className="w-100 pl-4 m-graphic-350">
                      <img src={Destroyer} alt="" className="img-fluid" />
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>

          <div className="mx-auto w-wrapper">
            <div className="mx-auto w-100 mb-0">
              <h2 className="claim-title lg text-white text-center">RARITY</h2>
              <div className="w-100 mb-100">
                <Table className="table-purple">
                  <thead>
                    <tr>
                      <th style={{ width: '50%' }}>VESSEL TYPE</th>
                      <th style={{ width: '20%' }}>MODELS</th>
                      <th style={{ width: '30%' }}>TOTAL SUPPLY</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td width="50%">
                        <div className="d-flex align-items-center">
                          <span className="size-32">
                            <img src={IconUfo} alt="UFO" className="img-fluid" />
                          </span>
                          UFO
                        </div>
                      </td>
                      <td width="20%">4</td>
                      <td width="30%">
                        <NumberFormat
                          value={ClaimBreakdownValues.LEVEL_1}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={''}
                        />{' '}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="size-32">
                            <img
                              src={IconDestroyer}
                              alt="DESTROYER"
                              className="img-fluid"
                            />
                          </span>
                          DESTROYER
                        </div>
                      </td>
                      <td>4</td>
                      <td>
                        <NumberFormat
                          value={ClaimBreakdownValues.LEVEL_2}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={''}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="size-32">
                            <img
                              src={IconBlastBike}
                              alt="BLAST BIKE"
                              className="img-fluid"
                            />
                          </span>
                          BLAST BIKE
                        </div>
                      </td>
                      <td>6</td>
                      <td>
                        <NumberFormat
                          value={ClaimBreakdownValues.LEVEL_3}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={''}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="d-flex align-items-center">
                          <span className="size-32">
                            <img
                              src={IconDropship}
                              alt="DROPSHIP"
                              className="img-fluid"
                            />
                          </span>
                          DROPSHIP
                        </div>
                      </td>
                      <td>10</td>
                      <td>
                        <NumberFormat
                          value={ClaimBreakdownValues.LEVEL_OTHERS}
                          displayType={'text'}
                          thousandSeparator={true}
                          prefix={''}
                        />
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="w-100 mb-24">
                <h3 className="claim-title lg text-white text-center no-border p-0 mb-48">
                  MINTING SPACECRAFT
                </h3>
                <ul>
                  <li className="fs-24 text-darkgrey text-left">
                    Each croc entitles the holder to mint a single spacecraft at the cost
                    of 800 $PAPER per spacecraft. don't forget to claim your $PAPER if you
                    have not already!
                  </li>

                  <li className="fs-24 text-darkgrey text-left">
                    After connecting your wallet you will be shown how many spacecraft you
                    are currently entitled to mint - this is a single mint for all of your
                    eligible crocs in a single transaction. Should you wish to conduct
                    separate transactions you will need to split your crocs into alternate
                    wallets before minting.
                  </li>

                  <li className="fs-24 text-darkgrey text-left">
                    Each croc's spacecraft can only be minted once, you can use the below
                    claim check to find out if a croc has previously been claimed.
                  </li>
                </ul>
              </div>
              <p className="fs-24 text-greyish text-center mb-0">
                The mint window will remain open for 60 days and any remaining allocation
                will be consulted with community to decide on the best course of action
                (e.g. cap supply, mint out for promotion etc).
              </p>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default SectionClaimBreakdown;
