import { FunctionComponent, useCallback, useState, FormEvent } from 'react';
import { toast } from 'react-toastify';
import { Input } from 'reactstrap';

import WalletApi from '../api/WalletApi';
import { getCatchError } from '../utils/errorHandler';

// Static Assets
// import OGMinter from "../assets/images/icons/og.gif";

interface Props {
  walletAddress: string;
}

const SectionClaimCheck: FunctionComponent<Props> = ({ walletAddress }: Props) => {
  const [claimCheckValue, setClaimCheckValue] = useState<string>('');
  const [cliamCheckLoader, setCliamCheckLoader] = useState<boolean>(false);
  const [errorType, setErrorType] = useState<boolean>(false);
  const [statusMsg, setStatusMsg] = useState<string>('');

  const claimCheckHandler = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        if (!claimCheckValue) {
          toast.error('Claim value is required');
          return;
        }
        setCliamCheckLoader(true);
        setErrorType(false);
        setStatusMsg('');

        const response = await WalletApi.checkClaimToken(parseInt(claimCheckValue));

        if (!response.data.tokenAvailable) {
          setErrorType(false);
          setStatusMsg('TOKEN AVAILABLE FOR CLAIM');
        } else {
          setErrorType(true);
          setStatusMsg('Sorry, this NFT was already used to mint.');
        }
      } catch (err: any) {
        toast.error(getCatchError(err)[0], {
          autoClose: 5000,
        });
      } finally {
        setCliamCheckLoader(false);
      }

      // eslint-disable-next-line
    },
    [claimCheckValue]
  );

  const claimCheckValueChange = (val: string) => {
    if (!val || val.match(/^\d{1,}(\.\d{0,4})?$/)) {
      setClaimCheckValue(val);
    }
  };

  return (
    <section className="section-claim-check">
      <div className="mx-auto w-wrapper">
        <div className="claim-check-block mx-auto">
          <div className="claim-check-block-inner mx-auto">
            <h2 className="claim-title text-center text-white mb-48">CLAIM CHECK</h2>
            <form
              className="w-100 form-horizontal"
              autoComplete="off"
              onSubmit={claimCheckHandler}
            >
              <div className="claim-check-input">
                <div className="check-input">
                  <Input
                    name="claimCheckValue"
                    id="claimCheckValue"
                    value={claimCheckValue}
                    onChange={(e) => claimCheckValueChange(e.target.value)}
                    autoComplete="off"
                  />
                </div>
                <button
                  type="submit"
                  className={`check-button ${cliamCheckLoader ? `is-loading` : ``}`}
                  disabled={cliamCheckLoader || !walletAddress ? true : false}
                >
                  <span>CHECK</span>
                </button>
              </div>
              {statusMsg && (
                <div className={`helper-block ${errorType ? `error` : `success`}`}>
                  {statusMsg}
                </div>
              )}
            </form>
          </div>
        </div>

        <div className="check-disclaimer">
          While this claim check is updated in real time by reading from the smart
          contract, it is possible for someone process the claim after you have performed
          the check (e.g. if you check while the claim transaction is pending, or they
          claim after you've ran the check). Once a successful claim is processed for a
          particular croc(s), they are then ineligible for any further claim.
        </div>
      </div>
    </section>
  );
};

export default SectionClaimCheck;
