import { useState, FunctionComponent, useEffect, Fragment } from 'react';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { Input } from 'reactstrap';

import { ITokenMapping } from '../api/types/wallet';
import { ModalStatus } from '../config/Constant';
import WalletApi from '../api/WalletApi';
import { getCatchError } from '../utils/errorHandler';
import { mintSpaceCraft, approveToken, checkTokenAllowance } from '../utils/interact';

// Static Assets
import DestroyerIcon from '../assets/images/icons/destroyer_animated.gif';

// Import UI
import ConfirmationModal from '../components/ConfirmationModal';
import CustomToastComponent from '../components/CustomToastComponent';
import AppConfig from '../config/config';

interface Props {
  walletAddress: string;
  freeClaimObj: ITokenMapping | null;
  web3Provider: ethers.providers.Web3Provider | null;
  reloadClaimDataHandler: (val: boolean) => void;
  connectWalletHandler: () => void;
  setShowLoader: (val: boolean) => void;
  tokenAmountHandler: (inputVal?: string, val?: boolean) => void;
  showLoader: boolean;
  tokenAmount: number;
}

const SectionHome: FunctionComponent<Props> = ({
  walletAddress,
  freeClaimObj,
  web3Provider,
  reloadClaimDataHandler,
  connectWalletHandler,
  showLoader,
  setShowLoader,
  tokenAmount,
  tokenAmountHandler,
}: Props) => {
  const [claimRequestLoader, setClaimRequestLoader] = useState<boolean>(false);
  const [modalStatus, setModalStatus] = useState<number>(ModalStatus.request); // 0 stable, 1 error, 2 success
  const [modalErrorMessage, setModalErrorMessage] = useState<Array<string>>([]); // 0 stable, 1 error, 2 success
  const [transaction, setTransaction] = useState<any>(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [isTokenApproved, setIsTokenApproved] = useState<boolean>(true);
  const [approveTokenLoader, setApproveTokenLoader] = useState<boolean>(false);

  const approveTokenHandler = async () => {
    setApproveTokenLoader(true);
    setModalStatus(ModalStatus.request);
    setConfirmModalOpen(!confirmModalOpen);
    setShowLoader(false);

    const response = await approveToken(web3Provider);

    if (response.error) {
      setModalStatus(ModalStatus.error);
      setModalErrorMessage([response.errorMessage]);
      setApproveTokenLoader(false);
      return;
    }

    setApproveTokenLoader(false);
    setTransaction(response.tx);
    setModalStatus(ModalStatus.success);

    // Pending status for transaction
    try {
      setShowLoader(true);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const receipt = await response.tx.wait();
      setShowLoader(false);

      setIsTokenApproved(true);

      toast.success(
        <CustomToastComponent
          message="Token spent approved successfully"
          link={`https://cronos.crypto.org/explorer/tx/${response.tx.hash}`}
        />
      );
    } catch (error: any) {
    } finally {
      setShowLoader(false);
    }
  };

  const checkTokenApproval = async () => {
    setApproveTokenLoader(true);
    setIsTokenApproved(false);
    if (walletAddress && web3Provider) {
      try {
        const response = await checkTokenAllowance(walletAddress, web3Provider);
        if (response.error) {
          toast.error(<CustomToastComponent message={response.errorMessage} />);
          return;
        }

        if (
          ethers.BigNumber.from(response.allowance.toString()).gt(
            ethers.BigNumber.from('0')
          )
        ) {
          setIsTokenApproved(true);
        }
      } catch (error: any) {
        toast.error(<CustomToastComponent message={error.message} />);
      }
    }

    setApproveTokenLoader(false);
  };

  const mintHandler = async () => {
    try {
      if (web3Provider) {
        setClaimRequestLoader(true);
        const signatureResponse = await WalletApi.getSignedSignature(
          walletAddress,
          tokenAmount
        );
        if (signatureResponse.data.signature) {
          setModalStatus(ModalStatus.request);
          setConfirmModalOpen(!confirmModalOpen);

          const response = await mintSpaceCraft(
            signatureResponse.data.tokenIds.length,
            signatureResponse.data.signature,
            signatureResponse.data.tokenIds
          );

          if (!response.success) {
            setModalStatus(ModalStatus.error);
            setModalErrorMessage([response.status]);
            setClaimRequestLoader(false);
            return;
          }

          setTransaction(response.tx);
          setModalStatus(ModalStatus.success);

          // Pending status for transaction
          try {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const receipt = await response.tx.wait();

            setClaimRequestLoader(false);
            reloadClaimDataHandler(true);

            toast.success(
              <CustomToastComponent
                message="SpaceCraft minted successfully"
                link={`${AppConfig.EXPLORER_URL}/tx/${response.tx.hash}`}
              />
            );
          } catch (error: any) {}
        }
      }
    } catch (err: any) {
      toast.error(getCatchError(err)[0]);
      setClaimRequestLoader(false);
      setModalStatus(ModalStatus.error);
      setModalErrorMessage(getCatchError(err));
    }
  };

  useEffect(() => {
    if (walletAddress && web3Provider) {
      checkTokenApproval();
    }
  }, [walletAddress]);

  return (
    <>
      <>
        <section id="Home" className="section-intro">
          <div className="section-intro-wrap">
            <div className="mint-frame-export">
              <div className="mint-frame-export-header">
                <h1 className="claim-title text-center">- MINT SPACECRAFT -</h1>
                <p className="fs-24 mb-0">
                  enter the amount of CRAFT you would like to purchase frens.
                </p>
              </div>
              <div className="mint-frame-export-body">
                <div className="d-flex align-items-center justify-content-between mb-24">
                  <div className="graphic-116">
                    <img src={DestroyerIcon} alt="" className="img-fluid" />
                  </div>
                  <div className="w-220 text-end">
                    <p className="fs-24 mb-0">price per craft 800 paper each</p>
                    {freeClaimObj && (
                      <p className="fs-24 mb-0">
                        {freeClaimObj.totalClaimableTokens} eligible
                      </p>
                    )}
                    {!freeClaimObj && <p className="fs-24 mb-0">---- eligible</p>}
                    <p className="fs-24 mb-0">&nbsp;</p>
                    {freeClaimObj && freeClaimObj.totalClaimableTokens > 50 && (
                      <p className="fs-20 mb-0">Max 50 per transaction</p>
                    )}
                  </div>
                </div>
                <div className="d-flex w-full align-items-center justify-content-center croc-spinner mb-2 sm:mmb-10">
                  <button
                    type="button"
                    className="croc-minus"
                    onClick={() => tokenAmountHandler(undefined, true)}
                  >
                    -
                  </button>
                  <div className="w-120 fs-24">
                    <Input
                      type="text"
                      className=""
                      value={tokenAmount}
                      onChange={(e) => tokenAmountHandler(e.target.value)}
                    />
                  </div>
                  <button
                    type="button"
                    className="croc-plus"
                    onClick={() => tokenAmountHandler(undefined, false)}
                  >
                    +
                  </button>
                </div>
                <div className="croc-total d-flex w-full align-items-center justify-content-between">
                  <div className="w-80 fs-24 u-upper">total</div>
                  <div className="ml-auto w-300 fs-24 u-upper text-right total-box">
                    <span className="text-grey">
                      {tokenAmount * AppConfig.PAPERTOKENS_PERMINT_AMOUNT} $PAPER
                    </span>
                  </div>
                </div>
              </div>
              <div className="mint-frame-export-footer" id="btn-claim">
                {!walletAddress && (
                  <button
                    type="button"
                    onClick={connectWalletHandler}
                    className={`btn-mint-craft ${showLoader ? `is-loading` : ``}`}
                    disabled={showLoader ? true : false}
                  >
                    <span>CONNECT</span>
                  </button>
                )}

                {walletAddress && (
                  <Fragment>
                    {isTokenApproved && (
                      <button
                        type="button"
                        className={`btn-mint-craft ${
                          claimRequestLoader ? `is-loading` : ``
                        }`}
                        onClick={mintHandler}
                        disabled={
                          !freeClaimObj ||
                          (freeClaimObj && freeClaimObj.totalClaimableTokens < 1)
                            ? true
                            : false
                        }
                      >
                        <span>MINT</span>
                      </button>
                    )}

                    {!isTokenApproved && (
                      <button
                        type="button"
                        className={`btn-mint-craft ${
                          approveTokenLoader ? `is-loading` : ``
                        }`}
                        onClick={approveTokenHandler}
                      >
                        <span>APPROVE</span>
                      </button>
                    )}
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </section>

        <ConfirmationModal
          isOpen={confirmModalOpen}
          modalToggler={setConfirmModalOpen}
          modalStatus={modalStatus}
          modalErrorMessage={modalErrorMessage}
          transaction={transaction}
        />
      </>
    </>
  );
};

export default SectionHome;
