import { useCallback, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';
import ReactGA from 'react-ga4';
import Web3Modal from 'web3modal';
import { toast } from 'react-toastify';
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';
import { ethers } from 'ethers';

import appConfig from './config/config';

import { NetworkId } from './config/Constant';
import { ITokenMapping } from './api/types/wallet';

// Menu
import Header from './layout/header';
import SectionHome from './sections/SectionHome';
import SectionPresale from './sections/SectionPresale';
import SectionClaimBreakdown from './sections/SectionClaimBreakdown';
import SectionClaimCheck from './sections/SectionClaimCheck';
import SectionAddMetamask from './sections/SectionAddMetamask';
import Footer from './layout/footer';

import MetamaskLogo from './assets/images/metamask-fox.svg';
import WalletApi from './api/WalletApi';
import AppConfig from './config/config';

function App() {
  const [walletAddress, setWalletAddress] = useState<string>('');
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showValidateLoader, setShowValidateLoader] = useState<boolean>(false);
  const [reloadClaimData, setReloadClaimData] = useState<boolean>(false);
  const [web3Provider, setWeb3Provider] =
    useState<ethers.providers.Web3Provider | null>(null);
  const [freeClaimObj, setFreeClaimObj] = useState<ITokenMapping | null>(null);
  const [tokenAmount, setTokenAmount] = useState<number>(1);

  const tokenAmountHandler = (inputValue?: string, isDecrement = false) => {
    console.log(inputValue);
    let value = isDecrement ? tokenAmount - 1 : tokenAmount + 1;
    if (inputValue !== undefined) {
      if (!inputValue || inputValue.match(/^[0-9]/)) {
        value = inputValue ? parseInt(inputValue) : 0;
      }
    }

    if (value < 0) {
      value = 0;
    }
    if (freeClaimObj && freeClaimObj.totalClaimableTokens > 0 && value === 0) {
      value = 1;
    }
    if (freeClaimObj && freeClaimObj.totalClaimableTokens === 0) {
      value = 0;
    }
    if (freeClaimObj && value > freeClaimObj.totalClaimableTokens) {
      value = freeClaimObj.totalClaimableTokens;
    }
    if (value > AppConfig.MAX_MINT_AMOUNT) {
      value = AppConfig.MAX_MINT_AMOUNT;
    }
    if (!freeClaimObj) {
      value = 1;
    }

    setTokenAmount(value);
  };

  const connectWalletHandler = async (type: string = '') => {
    setShowLoader(true);

    let providerOptions: any = {
      injected: {
        display: {
          logo: MetamaskLogo,
          name: 'MetaMask',
          description: 'Connect to your MetaMask Wallet',
        },
        package: null,
      },
      walletlink: {
        package: CoinbaseWalletSDK,
        options: {
          appName: 'ACROCALYPSE',
          infuraId: AppConfig.INFURA_ID,
        },
      },
    };

    let web3Provider: any;

    let web3Modal = new Web3Modal({
      cacheProvider: false,
      providerOptions,
      theme: {
        background: 'rgb(18, 18, 18)',
        main: 'rgb(223, 224, 226)',
        secondary: 'rgb(179, 180, 188)',
        border: 'rgba(54, 56, 69, 1)',
        hover: 'rgb(13, 13, 15)',
      },
    });

    if (web3Modal.cachedProvider) {
      web3Provider = await web3Modal.connect();
    } else {
      web3Provider = await web3Modal
        .connect()
        .then((web3provider: any) => web3provider)
        .catch((error: any) => {
          setShowLoader(false);
          return null;
        });
    }

    if (!web3Provider) {
      toast.error('No provider found', {
        autoClose: 10000,
      });
      return;
    }

    await subscribeProvider(web3Provider);

    try {
      const provider = new ethers.providers.Web3Provider(web3Provider);

      // verify chain id/network id
      const cid = await web3Provider.request({
        method: 'net_version',
      });

      const correctChain =
        parseInt(cid) === parseInt(NetworkId.mainnet) ||
        parseInt(cid) === parseInt(NetworkId.rinkeby);
      if (!correctChain) {
        toast.error(
          `You are on the wrong network. Please select Ethereum Mainnet.`,
          {
            autoClose: 60000,
          }
        );
        setShowLoader(false);
        return;
      }
      setWeb3Provider(provider);

      const accounts = await web3Provider.request({
        method: 'eth_accounts',
        params: [{ chainId: cid }],
      });

      const address = accounts[0];

      setWalletAddress(address);
      setShowLoader(false);
    } catch (error: any) {
      toast.error(error.message);
      if (type !== 'defi') {
        await web3Modal.clearCachedProvider();
      }
      setShowLoader(false);
    }
  };

  const subscribeProvider = async (web3provider: any) => {
    if (!web3provider.on) {
      return;
    }

    web3provider.on('disconnect', (_error: any) => {
      window.location.reload();
    });

    web3provider.on('accountsChanged', (accounts: any) => {
      if (accounts.length > 0) {
        setWalletAddress(accounts[0]);
      } else {
        setWalletAddress('');
        window.location.reload();
      }
    });

    web3provider.on('chainChanged', (_chainId: any) => {
      window.location.reload();
    });
  };

  const fetchClaimData = useCallback(async () => {
    try {
      setShowValidateLoader(true);

      const response = await WalletApi.getFreeClaims(walletAddress);
      setFreeClaimObj(response.data.tokenMapping);
      if (reloadClaimData) {
        setReloadClaimData(true);
      }
    } catch (err: any) {
      toast.error(err.message, {
        autoClose: 5000,
      });
      setWalletAddress('');
    } finally {
      setShowValidateLoader(false);
    }
  }, [walletAddress, reloadClaimData]);

  useEffect(() => {
    if (walletAddress) {
      fetchClaimData();
    }
    // eslint-disable-next-line
  }, [walletAddress]);

  useEffect(() => {
    if (walletAddress && reloadClaimData) {
      setTokenAmount(1);
      fetchClaimData();
    }

    // eslint-disable-next-line
  }, [walletAddress, reloadClaimData]);

  useEffect(() => {
    // initialize Google Analytic;
    ReactGA.initialize(appConfig.GA_TRACKING_ID);
    ReactGA.send({ hitType: 'pageview', page: '/' });
  }, []);

  return (
    <div className="app-wrapper">
      <Header
        connectWalletHandler={connectWalletHandler}
        walletAddress={walletAddress}
        showLoader={showLoader}
        showValidateLoader={showValidateLoader}
      />
      <main>
        <SectionHome
          walletAddress={walletAddress}
          freeClaimObj={freeClaimObj}
          web3Provider={web3Provider}
          reloadClaimDataHandler={setReloadClaimData}
          connectWalletHandler={connectWalletHandler}
          showLoader={showLoader}
          setShowLoader={setShowLoader}
          tokenAmount={tokenAmount}
          tokenAmountHandler={tokenAmountHandler}
        />
        <SectionPresale />
        <SectionClaimBreakdown />
        <SectionPresale />
        <SectionClaimCheck walletAddress={walletAddress} />
        <SectionAddMetamask />
      </main>
      <Footer walletAddress={walletAddress} />

      <ToastContainer autoClose={false} />
    </div>
  );
}

export default App;
