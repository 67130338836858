import dotenv from 'dotenv';
import * as SpaceCraftABI from '../artifacts/SpaceCraft.json';
import * as ERC20BurnableABI from '../artifacts/ERC20Burnable.json';
import { BigNumber, ethers } from 'ethers';
import AppConfig from '../config/config';

dotenv.config();

export const mintSpaceCraft = async (
  quantity: number,
  signature: string,
  tokenIds: Array<number>
) => {
  const GAS_LIMIT = 160000 + (tokenIds.length - 1) * 60000;

  if (!window.ethereum) {
    return {
      address: '',
      status: '🦊 You must install Metamask, a virtual Ethereum wallet, in your browser.',
    };
  }

  try {
    const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
    const paperToken = new ethers.Contract(
      process.env.REACT_APP_PAPER_CONTRACT_ADDRESS || '',
      ERC20BurnableABI.abi,
      signer
    );

    const walletPAPERBalance = await paperToken.balanceOf(await signer.getAddress());
    const totalPaperTokensNeeded = quantity * AppConfig.PAPERTOKENS_PERMINT_AMOUNT;
    if (parseInt(ethers.utils.formatEther(walletPAPERBalance)) < totalPaperTokensNeeded) {
      return {
        success: false,
        status: '😥 Not enough $PAPER tokens available',
      };
    }

    const contract = new ethers.Contract(
      process.env.REACT_APP_CONTRACT_ADDRESS || '',
      SpaceCraftABI.abi,
      signer
    );

    const tx = await contract.mint(quantity, signature, tokenIds, {
      gasLimit: GAS_LIMIT,
    });

    return {
      success: true,
      tx,
      status: '',
    };
  } catch (error: any) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message,
    };
  }
};

export const addPaperToMetamask = async () => {
  try {
    if (!window.ethereum) {
      return {
        success: false,
        status:
          '🦊 You must install Metamask, a virtual Ethereum wallet, in your browser.',
      };
    }

    // wasAdded is a boolean. Like any RPC method, an error may be thrown.
    const wasAdded = await window.ethereum.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address: AppConfig.REACT_APP_PAPER_CONTRACT_ADDRESS, // The address that the token is at.
          symbol: 'PAPER', // A ticker symbol or shorthand, up to 5 chars.
          decimals: 18, // The number of decimals in the token
        },
      },
    });

    return {
      success: true,
      status: '',
      wasAdded,
    };
  } catch (error: any) {
    return {
      success: false,
      status: '😥 Something went wrong: ' + error.message,
    };
  }
};

export const approveToken = async (
  web3Provider: ethers.providers.Web3Provider | null
) => {
  try {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(
      AppConfig.REACT_APP_PAPER_CONTRACT_ADDRESS,
      ['function approve(address spender, uint256 amount) external returns (bool)'],
      signer
    );

    const maxInt = BigNumber.from('2')
      .pow(BigNumber.from('256'))
      .sub(BigNumber.from('1'));
    const tx = await contract.approve(AppConfig.REACT_APP_CONTRACT_ADDRESS, maxInt);

    return {
      success: true,
      tx,
    };
  } catch (err: any) {
    return {
      error: true,
      errorMessage: err.message,
    };
  }
};

export const checkTokenAllowance = async (
  walletAddress: string,
  web3Provider: ethers.providers.Web3Provider | null
) => {
  try {
    const signer = web3Provider?.getSigner();
    const contract = new ethers.Contract(
      AppConfig.REACT_APP_PAPER_CONTRACT_ADDRESS,
      [
        'function allowance(address owner, address spender) external view returns (uint256)',
      ],
      signer
    );

    const allowance = await contract.allowance(
      walletAddress,
      AppConfig.REACT_APP_CONTRACT_ADDRESS
    );

    return {
      success: true,
      allowance,
    };
  } catch (err: any) {
    return {
      error: true,
      errorMessage: err.message,
      allowance: 0,
    };
  }
};
