import { FunctionComponent } from 'react';
import { toast } from 'react-toastify';

// Static Assets
import CroCheck from '../assets/images/croc_check_line-export2.gif';

import { addPaperToMetamask } from '../utils/interact';

interface Props {}

const SectionAddMetamask: FunctionComponent<Props> = () => {
  const addMetamaskHandler = async () => {
    const response = await addPaperToMetamask();
    if (response.success === false) {
      toast.error(response.status);
    }
    if (response.success === true) {
      if (response.wasAdded) {
        toast.success('PAPER added successfully');
      }
    }
  };

  return (
    <>
      <>
        <section className="section-add-metamask">
          <div className="mx-auto w-wrapper">
            <div className="mx-auto w-100 mb-70">
              <h2 className="claim-title lg text-white text-left mb-24">
                ADD PAPER TO METAMASK
              </h2>
              <p className="fs-24 text-left">
                Click the button below to add PAPER to your MetaMask wallet.
              </p>
            </div>
            <div className="add-wallet mx-auto d-flex align-items-center justify-content-between">
              <div className="corc-check">
                <img src={CroCheck} alt="CroCheck" className="img-fluid" />
              </div>
              <button
                type="button"
                className="add-wallet-button"
                onClick={addMetamaskHandler}
              >
                ADD TO METAMASK
              </button>
            </div>
          </div>
        </section>
      </>
    </>
  );
};

export default SectionAddMetamask;
