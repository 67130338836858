const development = {
  apiGateway: {
    BASE_URL: 'http://localhost:3000',
  },
};

const production = {
  apiGateway: {
    BASE_URL: 'https://spacecraftapi.acrocalypse.gg',
  },
};

let baseConfig = process.env.REACT_APP_STAGE === 'production' ? production : development;

const AppConfig = {
  REACT_APP_STAGE: process.env.REACT_APP_STAGE,
  REACT_APP_ETHERS_NETWORK: process.env.REACT_APP_ETHERS_NETWORK,
  REACT_APP_CONTRACT_ADDRESS: process.env.REACT_APP_CONTRACT_ADDRESS || '',
  REACT_APP_PAPER_CONTRACT_ADDRESS: process.env.REACT_APP_PAPER_CONTRACT_ADDRESS || '',
  INFURA_ID: process.env.REACT_APP_INFURA_ID || '',
  GA_TRACKING_ID: 'G-ZFSR55EP0T',
  BUY_ON_OS_URL: 'https://opensea.io/collection/acrocalypse',
  REACT_APP_VERSION: 1,
  EXPLORER_URL:
    process.env.REACT_APP_ETHERS_NETWORK === 'rinkeby'
      ? 'https://rinkeby.etherscan.io'
      : 'https://www.etherscan.io',
  MAX_MINT_AMOUNT: 50,
  PAPERTOKENS_PERMINT_AMOUNT: 800,
  ...baseConfig,
};

export default AppConfig;
