import { AxiosResponse } from 'axios';
import BaseApi from './BaseApi';
import {
  IClaimCheckTokenResponse,
  IGetFreeCliamResponse,
  ISignedSignatureResponse,
} from './types/wallet';

const WalletApi = {
  getFreeClaims: async (
    walletAddress: string
  ): Promise<AxiosResponse<IGetFreeCliamResponse>> => {
    return await BaseApi.getRequest(`/get-claim-stats?walletAddress=${walletAddress}`);
  },
  getSignedSignature: async (
    walletAddress: string,
    quantity: number
  ): Promise<AxiosResponse<ISignedSignatureResponse>> => {
    return await BaseApi.getRequest(
      `/create-hash?walletAddress=${walletAddress}&quantity=${quantity}`
    );
  },
  checkClaimToken: async (
    claimCheckValue: number
  ): Promise<AxiosResponse<IClaimCheckTokenResponse>> => {
    return await BaseApi.getRequest(`/check-claim-token?tokenId=${claimCheckValue}`);
  },
};

export default WalletApi;
