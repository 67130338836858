import { Container, Nav, NavItem } from 'reactstrap';
import { Link } from 'react-scroll';

// Static Assets
import LogoIcon from '../assets/images/logo-icon@2x.png';

interface Props {
  walletAddress: string;
}

function Footer({ walletAddress }: Props) {
  return (
    <footer className="footer-wrap">
      <Container className="">
        <div className="d-flex align-items-center justify-content-between">
          <Link
            to="Home"
            spy={true}
            smooth={true}
            offset={-50}
            duration={500}
            className="cursor-pointer"
          >
            <img src={LogoIcon} className="footer-logo" alt="Acrocalypse" />
          </Link>
          <Nav className="footer-social">
            <NavItem className="footer-social-item">
              <a
                href="https://twitter.com/acrocalypseNFT"
                className="footer-social-link"
                target="_blank"
                rel="noreferrer"
              >
                <span className="twitter-white">
                  <span className="sr-only">Twitter</span>
                </span>
              </a>
            </NavItem>
            <NavItem className="footer-social-item">
              <a href="https://discord.gg/7KZQNFMJrE" className="footer-social-link">
                <span className="discord-white">
                  <span className="sr-only">Discord</span>
                </span>
              </a>
            </NavItem>
            <NavItem className="footer-social-item">
              <a
                href="https://opensea.io/collection/acrocalypse"
                className="footer-social-link"
                target="_blank"
                rel="noreferrer"
              >
                <span className="opensea-white">
                  <span className="sr-only">Opensea</span>
                </span>
              </a>
            </NavItem>
            <NavItem className="footer-social-item">
              <a
                href="https://etherscan.io/address/0xd73acd7f5099fdd910215dbff029185f21ffbcf0"
                className="footer-social-link"
                target="_blank"
                rel="noreferrer"
              >
                <span className="etherscan-white">
                  <span className="sr-only">Etherscan</span>
                </span>
              </a>
            </NavItem>
          </Nav>

          {walletAddress && (
            <Link
              to="btn-claim"
              spy={true}
              smooth={true}
              offset={-50}
              duration={500}
              className="btn-mint-a-croc cursor-pointer"
            >
              MINT
            </Link>
          )}
        </div>
      </Container>
    </footer>
  );
}

export default Footer;
