import React from 'react';

interface SectionPresaleProps {}
interface SectionPresaleState {}

class SectionPresale extends React.Component<SectionPresaleProps, SectionPresaleState> {
  constructor(props: SectionPresaleProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="presale">
          <div className="presale-wrap">
            <div className="presale-text">
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / ACROCALYPSE IS COMING / SPACECRAFT CAN ONLY BE MINTED WITH
              $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE
              MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT
              CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
            </div>
            <div className="presale-text">
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN
              ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
              SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED
              WITH $PAPER / ACROCALYPSE IS COMING / SPACECRAFT CAN ONLY BE MINTED WITH
              $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE
              MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT
              CAN ONLY BE MINTED WITH $PAPER / SPACECRAFT CAN ONLY BE MINTED WITH $PAPER /
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default SectionPresale;
