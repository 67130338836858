export const NetworkId = {
  mainnet: '1',
  rinkeby: '4',
};

export const ModalStatus = {
  request: 0,
  error: 1,
  success: 2,
};

export const ClaimBreakdownValues = {
  LEVEL_1: 2420,
  LEVEL_2: 1000,
  LEVEL_3: 2000,
  LEVEL_OTHERS: 5000,
};
